import {
    Component, Input, OnChanges, OnInit, 
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-marquee-carousel',
    templateUrl: './marquee-carousel.component.html',
    styleUrl: './marquee-carousel.component.scss'
})
export class MarqueeCarouselComponent implements OnInit, OnChanges {
    items: Array<{
        image_url: string
    }> = [];
    @Input() duplication = 0;
    @Input() per_cycle_duration = 0;

    @Input() arr: Array<{
        image_url: string
    }> = [];

    ngOnInit(): void {
        this.generateArray();
    }

    generateArray() {
        const items = [];

        for (let i = 0; i < this.duplication; i++) 
            items.push(...this.arr);
        
        this.items = items;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['arr']?.currentValue) 
            this.generateArray();
    }
}
