<div class="app-invite-code container-fluid" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div class="code-input">
        <app-cf-otp (changed)="onChangeCode($event)" [prefillCode]="this.invite_code" [length]="6" type="text"
            label="Enter Invitation Code" (handlePaste)="this.invite_code" [showLabel]=" true" class="web"></app-cf-otp>

        <cf-input label="Enter Invitation Code" type="text" [showLabel]="true" class="mobile" [value]="invite_code"
            (valueChanged)="onChangeCode($event)" placeholder="Enter Invitation Code" [theme]="theme"></cf-input>
    </div>
    <div class="action">
        <div *ngIf="!loading">
            <cf-button label="Submit" (click)="submit()"></cf-button>
        </div>
        <div *ngIf="loading">
            <cf-loader />
        </div>
    </div>
</div>