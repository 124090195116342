// console.log('app.module.ts start');

import {
    LOCALE_ID, NgModule 
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import {
    FormsModule, ReactiveFormsModule 
} from '@angular/forms';
import {
    MatDialogModule, MatDialogRef 
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    CfAlertModule, CfCardModule, CfCheckboxModule, CfChipModule, CfDropdownModule, CfInputCurrencyDropdownModule, CfInputModule, CfToggleModule, CfLoaderModule, CfMobileNumberModule, CfButtonModule, CfPaginatorModule, CfInputCurrencyModule, CfTabsModule, CfOtpModule,
    CfCircularProgressBarModule
    
} from '@crediblefinance/credible-ui';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
    DatePipe, DecimalPipe 
} from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi
} from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NewInvestmentComponent } from './new-investment/new-investment.component';
import { AirdropComponent } from './airdrop/airdrop.component';
import { ClaimLoyaltyComponent } from './claim-loyalty/claim-loyalty.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewWithdrawalComponent } from './new-withdrawal/new-withdrawal.component';
import { BorrowPoolDetailsComponent } from './borrow-pool-details/borrow-pool-details.component';
import { NewRepaymentComponent } from './new-repayment/new-repayment.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import { AddHeaderInterceptor } from '../interceptors/add-header.interceptor';
import { LendComponent } from './lend/lend.component';
import { LendWidgetComponent } from './lend-widget/lend-widget.component';
import ButtonMenuComponent from './button-menu/button-menu.component';
import { PoolActivityComponent } from './pool-activity/pool-activity.component';
import { LendPoolTransactionsComponent } from './lend-pool-transactions/lend-pool-transactions.component';
import { LendPoolWithdrawalsComponent } from './lend-pool-withdrawals/lend-pool-withdrawals.component';
import { KycComponent } from './kyc/kyc.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BorrowWidgetComponent } from './borrow-widget/borrow-widget.component';
import { BorrowPoolLoansComponent } from './borrow-pool-loans/borrow-pool-loans.component';
import { BorrowPoolRepaymentsComponent } from './borrow-pool-repayments/borrow-pool-repayments.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { HomepageComponent } from './homepage/homepage.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { MyRepaymentsComponent } from './transactions/my-repayments/my-repayments.component';
import { MyWithdrawalsComponent } from './transactions/my-withdrawals/my-withdrawals.component';
import { NewDrawdownComponent } from './new-drawdown/new-drawdown.component';
import { MyEarningsComponent } from './transactions/my-earnings/my-earnings.component';
import { MyInvestmentsComponent } from './transactions/my-investments/my-investments.component';
import { BorrowerDashboardComponent } from './borrower-dashboard/borrower-dashboard.component';
import { NewEarningComponent } from './new-earning/new-earning.component';
import { MyDrawdownsComponent } from './transactions/my-drawdowns/my-drawdowns.component';
import { RegisterStepsComponent } from './register-steps/register-steps.component';
import { ConnectSocialsComponent } from './register-steps/connect-socials/connect-socials.component';
import { UserProfileComponent } from './register-steps/user-profile/user-profile.component';
import { VaultAllocationComponent} from './vault-allocation/vault-allocation.component';
import { MembersComponent } from './members/members.component';
import { ConnectWalletComponent } from './register-steps/connect-wallet/connect-wallet.component';
import { InviteCodeComponent } from './register-steps/invite-code/invite-code.component';
import { InvitationComponent } from './invitation/invitation.component';
import { ChooseProfilePictureComponent } from './register-steps/choose-profile-picture/choose-profile-picture.component';
import { CfOtpComponent } from './cfOtp/cf-otp.component';
import { HeaderPopupMenuComponent } from './header-popup-menu/header-popup-menu.component';
import { LendDefaultComponent } from './lend/lend-default/lend-default.component';
import { LendPrivateEquityComponent } from './lend/lend-private-equity/lend-private-equity.component';
import { LendPoolDetailsComponent } from './lend-pool-details/lend-pool-details.component';
import { LendPoolDetailsDefaultComponent } from './lend-pool-details/lend-pool-details-default/lend-pool-details-default.component';
import { LendPoolDetailsVaultComponent } from './lend-pool-details/lend-pool-details-vault/lend-pool-details-vault.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DisclosureComponent } from './register-steps/disclosure/disclosure.component';
import { BorrowPoolDetailsDefaultComponent } from './borrow-pool-details/borrow-pool-details-default/borrow-pool-details-default.component';
import { BorrowPoolDetailsVaultComponent } from './borrow-pool-details/borrow-pool-details-vault/borrow-pool-details-vault.component';
import { LoyaltyComponent } from './loyalty/loyalty.component';
import { SpinWheelComponent } from './airdrop/spin-wheel/spin-wheel.component';
import { MarqueeCarouselComponent } from './marquee-carousel/marquee-carousel.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatStepperModule,
        CfAlertModule, CfCardModule, CfCheckboxModule,
        CfChipModule, CfDropdownModule, CfInputCurrencyDropdownModule,
        CfInputModule, CfToggleModule, CfTabsModule,
        CfLoaderModule, CfMobileNumberModule, CfButtonModule, CfPaginatorModule,
        CfInputCurrencyModule, CfCircularProgressBarModule,
        AppRoutingModule,
        CfOtpModule,
        MatExpansionModule
    ],
    declarations: [
        AppComponent,
        CfOtpComponent,
        UserProfileComponent,
        FooterComponent,
        SidebarComponent,
        HeaderComponent,
        ConnectWalletComponent,
        DashboardComponent,
        NewInvestmentComponent,
        NewEarningComponent,
        NewWithdrawalComponent,
        AirdropComponent,
        ClaimLoyaltyComponent,
        MyInvestmentsComponent,
        MyRepaymentsComponent,
        BorrowPoolDetailsComponent,
        NewDrawdownComponent,
        MyEarningsComponent,
        MyWithdrawalsComponent,
        MyDrawdownsComponent,
        NewRepaymentComponent,
        SocialCallbackComponent,
        LendComponent,
        LendPoolDetailsComponent,
        LendWidgetComponent,
        BorrowWidgetComponent,
        ButtonMenuComponent,
        PoolActivityComponent,
        VaultAllocationComponent,
        LendPoolTransactionsComponent,
        LendPoolWithdrawalsComponent,
        KycComponent,
        NotFoundComponent,
        HomepageComponent,
        BorrowPoolLoansComponent,
        BorrowPoolRepaymentsComponent,
        LineChartComponent,
        TransactionsComponent,
        BorrowerDashboardComponent,
        RegisterStepsComponent,
        ConnectSocialsComponent,
        InviteCodeComponent,
        MembersComponent,
        ChooseProfilePictureComponent,
        InvitationComponent,
        CfOtpComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        HeaderPopupMenuComponent,
        LendDefaultComponent,
        LendPrivateEquityComponent,
        LendPoolDetailsDefaultComponent,
        LendPoolDetailsVaultComponent,
        VaultAllocationComponent,
        DisclosureComponent,
        HeaderPopupMenuComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        BorrowPoolDetailsDefaultComponent,
        BorrowPoolDetailsVaultComponent,
        LoyaltyComponent,
        SpinWheelComponent,
        MarqueeCarouselComponent
    ],
    exports: [ ButtonMenuComponent ],
    providers: [
        DecimalPipe,
        DatePipe,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-IN'
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false
            }
        },
        // provideClientHydration(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHeaderInterceptor,
            multi: true
        }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
