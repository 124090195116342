import IPool from '../interfaces/IPool';
import PoolStats from './PoolStats';

export default class Pool implements IPool {
    borrow_yield_currency: string = '';
    assets: string = '';
    borrower: string = '';
    category_id: string = '';
    category_name: string = '';
    country: string = '';
    created: number = 0;
    description: string = '';
    enabled: boolean = true;
    end_date: number = 0;
    expiry: number = 0;
    lending_yield_rates: Array<{
        days: number,
        apy: number,
        currency_percentage: number,
        cred_percentage: number
    }> = [];
    min_interest_rate: number = 0;
    max_interest_rate: number = 0;
    name: string = '';
    pool_id: string = '';
    start_date: number = 0;
    pool_size_usd: number = 0;
    status: string = '';
    tenor: number = 0;
    type: string = '';
    updated: number = 0;
    image_url: string = '';
    pool_type: string = '';
    borrow_yield_rate: number = 0;
    lender_fee_percentage: number = 0;
    borrower_fee_percentage: number = 0;
    min_borrow_amount: number = 0;
    approved_ltv_percentage: number = 0;
    min_lending_amount_per_tx: number = 0;
    max_lending_amount_per_wallet: number = 0;
    collateral_type: string = '';
    kyc_required: boolean = false;
    pool_asset: string = '';
    pool_network: string = '';
    pool_network_img_url: string = '';
    capped_pool: Boolean = false;
    pool_size_cap_usd: number = 0;
    borrower_overview: {
        rating_agency: string,
        rating_agency_url: string
        rating_agency_equivalent : string,
		implied_pd : string,
		data_room_url: string,
        repayment_frequency_days: string
    } = {
            rating_agency: '',
            rating_agency_url: '',
            rating_agency_equivalent: '',
            implied_pd: '',
            data_room_url: '',
            repayment_frequency_days: ''
        };
    lp_currency_mint_address: string = '';
    pool_pda: string = '';
    website: string = '';
    stats = new PoolStats();
}