import { KycStatusEnum } from '../enums/KycStatusEnum';
import IUser from '../interfaces/IUser';

export default class User implements IUser {
    account_type: string = '';
    first_name = '';
    email_verified = false;
    middle_name = '';
    last_name = '';
    created = 0;
    companyName: string = '';
    kyc_status: KycStatusEnum = KycStatusEnum.Pending;
    email = '';
    enabled = true;
    user_id = 0;
    address = '';
    mobile_number = '';
    isd_code: string = '';
    selfie_uploaded: boolean = false;
    pan_uploaded: boolean = false;
    aadhaar_uploaded: boolean = false;
    selfie_status: boolean | undefined = undefined;
    pan_status: boolean | undefined = undefined;
    aadhaar_status: boolean | undefined = undefined;
    user_payment_method_currency = 'inr';
    twoFAEnabled: boolean = false;
    wallet_address: string = '';
    provider: string = '';
    super_team_member: boolean = false;
    sol_balance_reward_claimed: boolean = false;
    referral_link: string = '';
    referral_code: number = 0;
    referred_by: number = 0;
    connect_socials_step: boolean = false;
    invite_code_step: boolean = false;
    display_name: string = '';
    profile_picture: string = '';
    terms_and_conditions: boolean = false;
    diamonds:number = 0;
}
