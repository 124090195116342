import {
    Component, HostListener, Input, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';

import { CfAlertService } from '@crediblefinance/credible-ui';
import { WalletService } from '../../services/wallet.service';
import WalletEvent from '../../models/WalletEvent';
import { LocalStorageService } from '../../services/localstorage.service';
import {
    ActivatedRoute, NavigationEnd, Router
} from '@angular/router';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import User from '../../models/User';
import { getWindow } from 'ssr-window';
import { SolanaNetwork } from '../../enums/SolanaNetwork';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ]

})
export class HeaderComponent implements OnInit {
    total_usd: number = 0;
    hidden_string: string = '';
    theme: any;
    width: any;
    window: Window;
    toggle: boolean = false;
    currentMintAddressMap: any = {};
    showCredibleLogo: boolean = false;
    current_url: string = '';
    tvl_usd = 0;
    displayConnectWalletButton: boolean = true;
    @Input() showConnectWallet: boolean = true;
    
    constructor(public httpService: HttpService, 
        private cfAlertService: CfAlertService, public walletService: WalletService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private phantomService: PhantomService,
        private solflareService: SolflareService) {
        this.window = getWindow();
    }

    isMenuCollapsed: boolean = true;

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.width = this.window.innerWidth;
        
        this.getLendingCurrencies();

        this.subscribeWalletObservables();
        this.getCurrentTheme();

        if (this.httpService.network === SolanaNetwork.MainnetBeta) 
            this.toggle = true;

        console.log(this.router.url, 'rourorr');

        if (this.router.url === '/connect-wallet' || this.router.url.includes('/connect-wallet') || this.httpService.checkUserOnboardingStatus() === true) 
            this.displayConnectWalletButton = false;

        this.subscribeRouteChangeEvents();
        this.getHomepageStats();
    }

    subscribeRouteChangeEvents() {
        console.log('subscribeRouteChangeEvents headers');

        this.current_url = this.router.url;

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            this.current_url = event.url;

            console.log('current_url subscribe', this.current_url);

            if (this.current_url === '/connect-wallet' || this.httpService.user.wallet_address !== '') 
                this.displayConnectWalletButton = false;
        });
    }

    getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild)
            return this.getChild(activatedRoute.firstChild);

        else
            return activatedRoute;
    }

    checkAddressValidity() {
        if (this.walletService.current_provider === 'phantom') {
            if (this.httpService.user.wallet_address !== this.phantomService.wallet_address) {
                console.log('Phantom address check failed', this.httpService.user.wallet_address, this.phantomService.wallet_address);
                this.logout();
            }
        }

        else if (this.walletService.current_provider === 'solflare') {
            if (this.httpService.user.wallet_address !== this.solflareService.wallet_address) {
                console.log('Solflare address check failed');
                this.logout();
            }
        }
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    connectWallet() {
        console.log('this.router.url', this.router.url);
        
        this.router.navigate([ '/connect-wallet' ], {
            skipLocationChange: true,
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getLendingCurrencies() {
        this.httpService.getLendingCurrencies().subscribe(res => {
            // this.currentMintAddressMap = res.data;

            res.data.forEach((element: any) => {
                this.currentMintAddressMap[element.currency_key] = element.mint_address;
            });
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getHiddenString() {
        const hidden_string = this.total_usd.toFixed(2);

        return hidden_string.replace(/[0-9]/g, 'X');
    }

    subscribeWalletObservables() {
        this.walletService.getEvent().subscribe((event: WalletEvent) => {
            console.log('subscribeWalletObservables', event);

            switch (event.name) {
                case 'connect':
                    console.log('connect', event);
                    console.log('httpService wallet_address', this.httpService.user.wallet_address);

                    if (this.addressExists(event.metadata.wallet_address) && this.addressExists(this.httpService.user.wallet_address)) {
                        if (event.metadata.wallet_address !== this.httpService.user.wallet_address) {
                            console.log('Address mismatch', event.metadata.wallet_address, this.httpService.user.wallet_address);
                            this.logout();
                        }
                    }

                    break;

                case 'disconnect':
                    this.logout();

                    break;

                case 'accountChanged':
                    console.log('hererkjbbbjkkbkkk +++++++++++++++++++');

                    if (event.metadata.new_address) {
                        this.cfAlertService.showError(new HttpErrorResponse({
                            error: `Switched to account ${event.metadata.new_address}`
                        }));

                        this.logout();
                    }

                    break;
            }
        });
    }

    addressExists(wallet_address: string) {
        if (wallet_address === '')
            return false;

        return true;
    }

    closeNavbar() {
        this.isMenuCollapsed = true;
    }

    gotoURL(url: any) {
        this.router.navigate([ url ]);
    }

    async logout() {
        if (this.walletService.current_provider === 'phantom') {
            this.phantomService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        else if (this.walletService.current_provider === 'solflare') {
            this.solflareService.disconnectWallet();
            this.walletService.current_provider = '';
        }

        this.localStorageService.clear();

        this.httpService.user = new User();
        this.httpService.registerStepsCompleted = false;
        this.localStorageService.setItem('rsc', 'false');

        await this.router.navigate([ '/' ], {
            replaceUrl: true
        });

        this.window.location.reload();
    }

    changeNetwork(toggle: boolean) {
        this.httpService.network = toggle ? SolanaNetwork.MainnetBeta : SolanaNetwork.Devnet;
        
        this.localStorageService.setItem('network', this.httpService.network);
        
        this.window.location.href = '/';
    }

    getNetworkName() {
        return this.httpService.network === SolanaNetwork.MainnetBeta ? 'Mainnet Beta' : 'Devnet';
    }

    redirectToDashboard() {
        if (this.httpService.user.wallet_address === '')
            this.router.navigate([ '/connect-wallet' ]);

        else
            this.router.navigate([ '/dashboard' ]);
    }
    getHomepageStats() {
        this.httpService.getHomepageStats().subscribe(res => {
            this.tvl_usd = res.data.tvl_usd;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
